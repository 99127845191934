import React, { useState, useEffect } from 'react'
import FacilitiesComp from '../comp/FacilitiesComp'
import Loading from '../comp/Loading';
import firebase from "../base.js";
import moment from "moment";


function FacilitiesScreen() {

    const [list, setlist] = useState([])
    const [loading, setloading] = useState(true)

    useEffect(() => {

        const subscriber = firebase.firestore()
            .collection('facilities')
            .orderBy("timestamp", "desc")
            .onSnapshot(querySnapshot => {
                const list_ = [];
                querySnapshot.forEach(doc => {

                    list_.push({
                        ...doc.data(),
                        key: doc.id
                    });
                });

                setlist(list_);
                setloading(false);
            });

        // Unsubscribe from blogs when no longer in use
        return () => subscriber();
    }, []);

    if (loading) {
        return (
            <Loading />
        )
    }


    return (
        <div>
            {
                list?.map((item, index) => {
                    return (
                        <FacilitiesComp item={item} />
                    )
                })
            }
        </div>
    )
}

export default FacilitiesScreen