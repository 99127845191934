import React, { useEffect, useState } from 'react'
import Loading from '../comp/Loading';
import firebase from "../base.js";
import moment from 'moment';

function MandatoryDocScreen() {

    const [list, setlist] = useState([])
    const [loading, setloading] = useState(true)

    useEffect(() => {

        const subscriber = firebase.firestore()
            .collection('mandatory_doc')
            .orderBy("timestamp", "asc")
            .onSnapshot(querySnapshot => {
                const list_ = [];
                querySnapshot.forEach(doc => {
                    list_.push({
                        ...doc.data(),
                        key: doc.id
                    });
                });

                setlist(list_);
                setloading(false);
            });

        // Unsubscribe from blogs when no longer in use
        return () => subscriber();
    }, []);


    if (loading) {
        return (
            <Loading />
        )
    }

    return (
        <div className='admission'>
            <h3>Mandatory Documents</h3>
            <div className='alerts_rows'>
                {
                    list?.map((item,index)=>{
                        return(
                            <p key={index}><i class="fa fa-asterisk"></i> Last updated on {moment(item?.timestamp).fromNow()} <br></br> <a href={item?.doc_link}>  {item?.doc_name}</a> </p>
                        )
                    })
                }
                
            </div>
        
        </div>
    )
}

export default MandatoryDocScreen