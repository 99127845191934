import React from 'react'

function FeedbackFormScreen() {
  return (
    <div class="form_main_grid">
    <div class="form_comp">
      <h1>Feedback Form : Government Polytechnic College, Damoh </h1>
      <h4>We value your feedback to help us improve. Please take a few minutes to share your thoughts about your experience at Government Polytechnic College, Damoh. Your responses will remain confidential 
        <a href="https://forms.gle/7KfsJyLdJqVaWuvaA"> Click here to fill the feedback form</a></h4>
    </div>
    <div class="form_comp">
      
      
    </div>
  </div>
  )
}

export default FeedbackFormScreen