import React from 'react'

function FooterComp() {
    return (

        <div className='footer'>
            <div className='footer_grid'>

                <div className='footer_comp'>
                    <div className='footer_comp_head'>
                        <p>About College</p>
                    </div>

                    <div className='footer_comp_content'>
                        <p>To develop technical man power through excellent infrastructure in polytechnic institutions for social, economic development of the state, to uplift the standard of living of people especially poor, scheduled castes/scheduled tribes, minorities, women and PWDs. To provide training having low cost effect/prepare skilled technicians and labourers those can get employment through formal and informal education/methods. To add important contribution in providing the respectful employment to general public.</p>
                    </div>

                </div>

                <div className='footer_comp'>
                    <div className='footer_comp_head'>
                        <p>Contact Us</p>
                    </div>

                    <div className='footer_comp_content'>
                        <p><mark>Address</mark>: Govt Polytechnic Hostel Bulding, Jabalpur Rd, near Polytecnic College, Choupra Khurd, Aam Choumpra, Madhya Pradesh 470661</p>
                        <p><mark>Phone</mark>: 078122 24404</p>
                        <p><mark>Email</mark>: rishiyadavjec@gmail.com</p>
                    </div>
                </div>

                
                <div className='footer_comp'>
                    <div className='footer_comp_head'>
                        <p>Social Links</p>
                    </div>

                    <div className='social_links'>
                        <p onClick={()=>window.open("https://www.instagram.com/reel/DB3ESVEySnB/?igsh=MXdsbHRjcHJtYTZhMw==")}><i class="fa fa-instagram" aria-hidden="true"></i></p>
                        <p onClick={()=>window.open("https://www.facebook.com/share/p/VbTPFWDMUBZJ9p4G/")}><i class="fa fa-facebook-official" aria-hidden="true"></i></p>
                        <p onClick={()=>window.open("https://youtube.com/@polytechdamoh9620?si=LID5isjrA-H1lGNQ")}><i class="fa fa-youtube-play" aria-hidden="true"></i>                        </p>
                        <p onClick={()=>window.open("https://x.com/pradesh85054?t=011EqG3CBSVXeK03VpDvLw&s=08")}><i class="fa fa-twitter-square" aria-hidden="true"></i></p>

                    </div>
                </div>

            </div>
            <p className='footer_copyright_line'>© 2023 All Rights Reserved Terms of Use and Privacy Policy</p>
        </div>

    )
}

export default FooterComp