import React from 'react'

function AlumniScreen() {
    return (
        <div class="alumni_form">
            <h1>Government Polytechnic College Alumni Details Collection Form</h1>
            <p>Dear Alumni,
                We are gathering information about our esteemed alumni to strengthen our alumni network and enhance college activities. Please fill out the form with your current details. Your information will be kept confidential and used solely for college purposes.  </p>
            <p>Form Link - <a href='https://forms.gle/e1rdxy7ZjAUoUc5R8'>https://forms.gle/e1rdxy7ZjAUoUc5R8</a></p>
        </div>
    )
}

export default AlumniScreen