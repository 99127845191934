import React from 'react'
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import img1 from "../assets/1.jpg";
import img2 from "../assets/gpcdamoh.jpg";
import img3 from "../assets/3.jpg";


const AutoplaySlider = withAutoplay(AwesomeSlider);

function BannerComp() {
    return (
        <div className='banner'>

            <div className='slider'>
                <AutoplaySlider
                    
                    
                    cancelOnInteraction={false} // should stop playing on user interaction
                    interval={6000}
                    bullets={false}
                >
                    <div data-src={img2} />
                    
                </AutoplaySlider>
            </div>

            {/* <div className='alerts'>
                <div className='alerts_head'>
                    <h3>Important alerts</h3>
                </div>
                {
                    [1,2,3,4].map((item,index)=>{
                        return(
                            <div className='alerts_row'>
                                <h5>The Kalaniketan Institution was merged in the Government Polytechnic institution</h5>
                                <p>The Kalaniketan Institution was merged in the Government Polytechnic institution</p>
                            </div>
                        )
                    })
                }
            </div> */}
        </div>
    )
}

export default BannerComp