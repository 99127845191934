import React ,{useState,useEffect}from 'react'
import firebase from '../base.js';

function SyllabusComp({branchName, items }) {
  const branch = {
    "cs":"Computer Science Engineering",
    "ee":"Electrical Engineering",
    "civil":"Civil Engineering",
    "mech":"Mechanical Engineering",
    "et":"Electonics Telecommunication"
  }

  return (
    <div className='syllabus_comp'>
      <div className='syllabus_head'>
        <h4>{branch[branchName]}</h4>
        <h4></h4>
      </div>

      <div className='syllabus_content'>
        {
          items?.map((item, index) => {
            return (
              <div className='syllabus_rows'>
                <p>{item?.title}</p>
                <p className='syllabus_download_link' onClick={()=>window.open(item?.link)}>Download</p>
              </div>
            )
          })
        }

      </div>

    </div>
  )
}



function SyllabusScreen() {

  const [list, setlist] = useState([])
  const [loading, setloading] = useState(true)

  useEffect(() => {

    const subscriber = firebase.firestore()
      .collection('syllabus')
      .orderBy("timestamp", "desc")
      .onSnapshot(querySnapshot => {
        const list_ = [];
        querySnapshot.forEach(doc => {

          list_.push({
            ...doc.data(),
            key: doc.id
          });
        });

        setlist(list_);
        setloading(false);
      });

    // Unsubscribe from blogs when no longer in use
    return () => subscriber();
  }, []);


  const getList = (tag) => {
    return list.filter(obj => obj.department === tag);
  }

  return (
    <div className='syllabus'>
      <SyllabusComp branchName={"civil"}  items={getList("civil")} />
      <SyllabusComp branchName={"mech"}  items={getList("mech")} />
      <SyllabusComp branchName={"et"}  items={getList("et")} />
      <SyllabusComp branchName={"ee"}  items={getList("ee")}/>
      <SyllabusComp branchName={"cs"}  items={getList("cs")}/>
    </div>
  )
}

export default SyllabusScreen