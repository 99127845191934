import React, { useEffect, useState } from 'react'
import firebase from "../base.js";

import moment from "moment";
import Loading from '../comp/Loading.js';
import FacilitiesComp from '../comp/FacilitiesComp.js';

function AdmissionScreen() {

    const [list, setlist] = useState([])
    const [addmission_proces, setaddmission_proces] = useState([])

    const [loading, setloading] = useState(true)

    useEffect(() => {

        const subscriber = firebase.firestore()
            .collection('admission')
            .orderBy("timestamp", "desc")
            .onSnapshot(querySnapshot => {
                const list_ = [];
                querySnapshot.forEach(doc => {

                    list_.push({
                        ...doc.data(),
                        key: doc.id
                    });
                });

                setlist(list_);
                setloading(false);
            });

        // Unsubscribe from blogs when no longer in use
        return () => subscriber();
    }, []);

    useEffect(() => {

        const subscriber = firebase.firestore()
            .collection('addmission_process')
            .orderBy("timestamp", "asc")
            .onSnapshot(querySnapshot => {
                const list_ = [];
                querySnapshot.forEach(doc => {

                    list_.push({
                        ...doc.data(),
                        key: doc.id
                    });
                });

                setaddmission_proces(list_);
                setloading(false);
            });

        // Unsubscribe from blogs when no longer in use
        return () => subscriber();
    }, []);


    if (loading) {
        return (
            <Loading />
        )
    }


    return (
        <div className='admission'>

            {
                addmission_proces?.map((item, index) => {
                    return (
                        <FacilitiesComp item={item} />
                    )
                })
            }

            {/* <h3>Admission Notifications</h3>

            <div className='admission_page'>


                {
                    list?.map((item, index) => {
                        return (
                            <div key={index}>
                                <h3>Call Now For More Information : <a href='tel:+91 883 938 3560'>+91 883 938 3560</a></h3>
                                <h3>Submit the details here : <a target="_blank" href=' https://forms.gle/xgMr9HDpnCeobzoY8'>Click here to submit the form</a></h3>

                                <img className='admission_img' src={item?.img} alt="" />
                            </div>
                        )
                    })
                }

            </div> */}


        </div>
    )
}

export default AdmissionScreen