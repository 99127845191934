import React from 'react'

function FacilitiesComp({item}) {
  return (
    <div className='fac_comp'>
        <div className='fac_head'>
            <h4>{item?.title}</h4>
        </div>

        <div className={item?.img ? 'fac_grid' : 'fac_grid fac_border'} >
            {item?.img && <div className='fac_img'>
                <img src={item?.img} alt="" />
            </div>}

            <div className='fac_content'>
                <p>{item?.text}</p>
            </div>

        </div>
    </div>
  )
}

export default FacilitiesComp